import { types } from "../types/types";

export const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case types.DataResumenDate:
      return {
        ...state,
        diaSeleccionadoNew: action.payload.fecha,
        horarioSeleccionadoNew: action.payload.horario,
        patenteNew: action.payload.patente,
      };

    default:
      return state;
  }
};
