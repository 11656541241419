import React from "react";
import ArrowDisabled from "../../images/san-jorge/arrow-disabled.png";
import "./style.css";

const SelectComponent = ({
  label,
  value,
  show,
  active,
  placeholder,
  isCalendar,
}) => {
  return (
    <>
      <p className="label-inputs">{label}</p>
      <div
        onClick={show}
        className="contain-select"
        style={{
          marginBottom: active ? "0px" : "20px",
        }}
      >
        {value !== "" ? (
          <div className="value-inputs">
            {isCalendar ? value?.toLocaleDateString() : value}
          </div>
        ) : (
          <div className="placeholder-inputs">{placeholder}</div>
        )}
        <img src={ArrowDisabled} alt="arrow-select" className="arrow-select" />
      </div>
    </>
  );
};

export default SelectComponent;
