import { types } from "../types/types";

export const turnosReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GetTurnos:
      return {
        horarioSeleccionado: action.payload.horarioSeleccionado,
        diaSeleccionado: action.payload.diaSeleccionado,
        marca: action.payload.marca,
        version: action.payload.version,
        modelo: action.payload.modelo,
        año: action.payload.año,
      };

    case types.PutTurnos:
      return {
        ...state,
        data: action.payload.data,
      };

    case types.DataResumen:
      return {
        ...state,
        marca: action.payload.brand,
        color: action.payload.color,
        km: action.payload.km,
        modelo: action.payload.model,
        version: action.payload.version,
        año: action.payload.year,
        diaSeleccionado: action.payload.fecha,
        horarioSeleccionado: action.payload.horario,
        patente: action.payload.patente,
      };

    case types.DataResumenVehiculo:
      return {
        ...state,
        marca: action.payload.brand,
        color: action.payload.color,
        km: action.payload.km,
        modelo: action.payload.model,
        version: action.payload.version,
        año: action.payload.year,
        patente: action.payload.patente,
      };

    case types.Loading:
      return {
        ...state,
        loading: action.payload.loading,
        status: action.payload.status,
      };

    default:
      return state;
  }
};
