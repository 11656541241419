import React from "react";
import "./style.css";
import { Spinner } from "react-bootstrap";

const LoadingComponent = ({ margin }) => {
  return (
    <div className="contain-loading">
      <p
        className="title-card"
        style={{ fontSize: "20px", margin: "0px", textAlign: "center" }}
      >
        Aguarde un momento, por favor ...
      </p>
      <Spinner
        animation="border"
        variant="primary"
        className="mt-4"
        style={{ width: "35px", height: "35px" }}
      />
    </div>
  );
};

export default LoadingComponent;
