export const types = {
  GetTurnos: "[Turnos] Get Turnos",
  PutTurnos: "[Turnos] Put Turnos",
  DataResumen: "[Turnos] Data Resumen",
  DataResumenDate: "[Turnos] Data Resumen Date",
  DataResumenVehiculo: "[Turnos] Data Resumen Vehiculo",
  login: "[Auth] Login",
  logout: "[Auth] Logout",
  Loading: "[Input] Start Loading",
};
