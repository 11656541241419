import React from "react";
import DayPicker from "react-day-picker";
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from "../../constants/date";
import "./style.css";

const CalendarComponent = ({
  activeCalendar,
  conc,
  fechaActual,
  sethorarioSeleccionado,
  addWeeks,
  isBefore,
  email,
  isTake,
  isAfter,
  seleccionarDia,
  companySelected,
}) => {
  return (
    <>
      <div className={activeCalendar ? "container-calendar" : "displayNone"}>
        {activeCalendar ? (
          <>
            {conc !== undefined ? (
              <DayPicker
                months={MONTHS}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                initialMonth={
                  fechaActual.getUTCDate() >= 20
                    ? new Date(2024, fechaActual.getMonth())
                    : null
                }
                fromMonth={new Date(2024, fechaActual.getMonth())}
                toMonth={
                  fechaActual.getUTCDate() >= 20
                    ? new Date(2024, fechaActual.getMonth() + 1)
                    : new Date(2024, fechaActual.getMonth())
                }
                onDayClick={(day) => {
                  sethorarioSeleccionado("");
                  // Normaliza las fechas para la comparación
                  const normalizedDisabledDays = conc.map(
                    (disabledDay) =>
                      new Date(
                        disabledDay.from || disabledDay.before || disabledDay
                      )
                  );

                  // Calcula la fecha una semana después de la fecha actual
                  const unaSemanaDespues = addWeeks(new Date(), 1);

                  // Verifica si el día está antes de la fecha actual, después de una semana desde la fecha actual,
                  // o está deshabilitado antes de llamar a seleccionarDia
                  const isDisabled = normalizedDisabledDays.some(
                    (disabledDay) =>
                      isBefore(day, new Date()) || (!email && !isTake)
                        ? isAfter(day, unaSemanaDespues)
                        : false
                  );

                  if (!isDisabled) {
                    seleccionarDia(day);
                  }
                }}
                disabledDays={conc}
              />
            ) : (
              <p
                style={{
                  margin: "20px 0px 10px 0px",
                  fontSize: "12px",
                  color: "#393839",
                }}
              >
                Cargando dias disponibles, espere...
              </p>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};

export default CalendarComponent;
