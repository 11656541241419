import { googleAuthProvider, firebase } from "../firebase/firebase-config";
import { types } from "../types/types";
import Swal from "sweetalert2";

export const startGoogleLogin = () => {
  return (dispatch) => {
    firebase
      .auth()
      .signInWithPopup(googleAuthProvider)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName, user.email));
        Swal.fire("Login", "Te logueaste correctamente.", "success");
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const login = (uid, displayName, email) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
    email,
  },
});

export const startLogout = () => {
  return async (dispatch) => {
    await firebase.auth().signOut();
    dispatch(logout());
  };
};

export const logout = () => ({
  type: types.logout,
});
