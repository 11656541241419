import Swal from "sweetalert2";
import { types } from "../types/types";
import axios from "axios";

export const cancelarTurnoDbTake = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "pretoma",
    agent: email,
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Cancelado", "Turno cancelado correctamente.", "success");
      dispatch(sendPutTurnos("cancelar"));
      navigate(`/turnos-san-jorge/take/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const modificarTurnoDbTake = async (
  deal_id,
  email,
  navigate,
  dispatch,
  setLoadingResponse
) => {
  const data = {
    year: "0000",
    month: "",
    day: "0",
    time: "",
    dealId: deal_id,
    type: "pretoma",
    agent: email,
  };

  let response = axios
    .post("https://infoauto-348420.rj.r.appspot.com/appointment", data)
    .then((dataDb) => {
      setLoadingResponse(false);
      Swal.fire("Realizado", "Ya podés editar tu turno", "success");
      dispatch(sendPutTurnos("modificar"));
      navigate(`/turnos-san-jorge/take/${deal_id}`);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const dataResumenTake = async (dispatch, deal_id) => {
  axios
    .get(
      `https://infoauto-348420.rj.r.appspot.com/get/appointment/pretake/${deal_id}`
    )
    .then((response) => {
      // Obtenemos los datos
      const { car, appointment, current_appointment, patente } = response.data;
      if (current_appointment) {
        dispatch(
          startDataResumen(
            car.brand,
            car.color,
            car.km,
            car.model,
            car.version,
            car.year,
            appointment.fecha,
            appointment.horario,
            patente
          )
        );
      } else {
        dispatch(
          startDataResumenVehiculo(
            car.brand,
            car.color,
            car.km,
            car.model,
            car.version,
            car.year,
            patente
          )
        );
      }
    })
    .catch((e) => {
      // Capturamos los errores
      console.log(e);
    });
};

const startDataResumenVehiculo = (
  brand,
  color,
  km,
  model,
  version,
  year,
  patente
) => ({
  type: types.DataResumenVehiculo,
  payload: {
    brand,
    color,
    km,
    model,
    version,
    year,
    patente,
  },
});

const startDataResumen = (
  brand,
  color,
  km,
  model,
  version,
  year,
  fecha,
  horario,
  patente
) => ({
  type: types.DataResumen,
  payload: {
    brand,
    color,
    km,
    model,
    version,
    year,
    fecha,
    horario,
    patente,
  },
});

export const dataResumenDate = async (dispatch, fecha, horario) => {
  dispatch(startDataResumenDate(fecha, horario));
};

export const startDataResumenDate = (fecha, horario) => ({
  type: types.DataResumenDate,
  payload: {
    fecha,
    horario,
  },
});

export const sendPutTurnos = (data) => ({
  type: types.PutTurnos,
  payload: {
    data,
  },
});
