import { combineReducers } from "redux";
import { turnosReducer } from "./turnosReducer";
import { dataReducer } from "./dataReducer";
import { authReducer } from "./authReducer";

export const rootReducer = combineReducers({
  dataReducer,
  turnosReducer,
  authReducer,
});
