import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCwJZ9BRWzX9vvGoQoN7dDH1TKZPM98wzc",
  authDomain: "web-turnos-342414.firebaseapp.com",
  projectId: "web-turnos-342414",
  storageBucket: "web-turnos-342414.appspot.com",
  messagingSenderId: "570462746326",
  appId: "1:570462746326:web:c62db132e4ab5c8a023b77",
  measurementId: "G-L40JVVCKS3",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export { db, googleAuthProvider, firebase };
