import React from "react";
import "./style.css";

const ButtonComponent = ({ type, text, handleEvent }) => {
  const styleButton = {
    backgroundColor: type === "contain" ? "#29a1dc" : "#ffffff",
    marginLeft: type === "contain" ? "5px" : "",
    marginRight: type === "contain" ? "" : "5px",
    border: `1px solid ${type === "contain" ? "" : "#29a1dc"}`,
    color: type === "contain" ? "#ffffff" : "#29a1dc",
  };

  return (
    <>
      <button onClick={handleEvent} className="custom-btn" style={styleButton}>
        {text}
      </button>
    </>
  );
};

export default ButtonComponent;
