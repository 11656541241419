import React from "react";
import { agendar } from "../../services/turnos";

const ButtonSendComponent = ({
  locationSelect,
  patenteValida,
  diaSeleccionado,
  horarioSeleccionado,
  isTake,
  valuePatente,
  deal_id,
  navigate,
  email,
  dispatch,
  setmodal,
}) => {
  return (
    <>
      <button
        className="btn-send-appointment"
        style={{
          backgroundColor:
            (locationSelect !== "" &&
              patenteValida &&
              diaSeleccionado &&
              horarioSeleccionado !== "") ||
            (isTake && diaSeleccionado && horarioSeleccionado !== "")
              ? "#29a1dc"
              : "#e2e2e2",
          cursor:
            (locationSelect !== "" &&
              patenteValida &&
              diaSeleccionado &&
              horarioSeleccionado !== "") ||
            (isTake && diaSeleccionado && horarioSeleccionado !== "")
              ? "pointer"
              : "auto",
        }}
        onClick={() =>
          (locationSelect !== "" &&
            patenteValida &&
            diaSeleccionado &&
            horarioSeleccionado !== "") ||
          (isTake && diaSeleccionado && horarioSeleccionado !== "")
            ? agendar(
                diaSeleccionado,
                horarioSeleccionado,
                valuePatente,
                deal_id,
                navigate,
                email,
                dispatch,
                setmodal,
                isTake ? "pretoma" : "inspeccion",
                isTake
                  ? "Av. Independencia 3050, CABA - San Jorge"
                  : locationSelect
              )
            : null
        }
      >
        Agendar trámite
      </button>
    </>
  );
};

export default ButtonSendComponent;
