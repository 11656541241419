import React from "react";
import LogoSanJorge from "../../images/san-jorge/logo-san-jorge.png";
import "./style.css";

const LogoComponent = () => {
  return (
    <div>
      <img
        src={LogoSanJorge}
        alt="logo-san-jorge"
        className="logo-company-san-jorge"
        style={{ marginTop: "50px" }}
      />
    </div>
  );
};

export default LogoComponent;
