import React from "react";
import { useDispatch } from "react-redux";
import { startGoogleLogin } from "../actions/auth";
import GoogleIcon from "../images/Google_Icon.png";
import LogoComponent from "./Logo/LogoComponent";

export const Login = () => {
  const dispatch = useDispatch();

  const googleLogin = () => {
    dispatch(startGoogleLogin());
  };

  return (
    <div className="container-app">
      <LogoComponent />
      <div className="card-login">
        <p className="title-login">Iniciar sesión</p>
        <button onClick={googleLogin} className="btn-login-google">
          <img
            src={GoogleIcon}
            alt="google-icon"
            className="google-icon-login"
          />
          Ingresar con Google
        </button>
      </div>
    </div>
  );
};
