import React from "react";
import "../Select/style.css";
import "./style.css";

const PlateComponent = ({
  valuePatente,
  handleInputChange,
  diaSeleccionado,
  horarioSeleccionado,
  locationSelect,
}) => {
  return (
    <>
      <p className="label-inputs">Patente</p>
      <input
        type="text"
        maxLength={7}
        value={valuePatente}
        placeholder="Ingresar la patente"
        className="input-plate"
        onChange={handleInputChange}
        disabled={
          diaSeleccionado && horarioSeleccionado !== "" && locationSelect !== ""
            ? false
            : true
        }
      />
    </>
  );
};

export default PlateComponent;
