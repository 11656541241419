import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import ButtonComponent from "../Buttons/ButtonComponent";
import "./style.css";
import LoadingComponent from "../Loading/LoadingComponent";

const ModalCancelEditComponent = ({
  show,
  setShow,
  text,
  handleEvent,
  loadingResponse,
}) => {
  const handleClose = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body>
        {loadingResponse ? (
          <div className="pt-4 pb-4">
            <LoadingComponent />
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center flex-column pt-4 pb-4">
            <p className="title-header-modal">¿Querés {text} tu turno?</p>
            <div className="contain-btn-modal">
              <ButtonComponent
                type="outline"
                text={"No"}
                handleEvent={handleEvent}
              />
              <ButtonComponent
                type="contain"
                text={`${text.charAt(0).toUpperCase() + text.slice(1)} turno`}
                handleEvent={handleEvent}
              />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalCancelEditComponent;
