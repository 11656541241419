import React from "react";
import "./style.css";
import { Modal } from "react-bootstrap";
import LoadingComponent from "../Loading/LoadingComponent";

export const ModalLoading = ({ modal, setmodal, loading, status }) => {
  const handleClose = () => {
    setmodal(false);
  };

  return (
    <Modal show={modal} onHide={handleClose} centered>
      <Modal.Body>
        {loading ? (
          <div className="pt-4 pb-4">
            <LoadingComponent />
          </div>
        ) : null}
        {!loading && status !== 200 ? (
          <p className="title-header-modal">Error</p>
        ) : !loading && status === 200 ? (
          <p className="title-header-modal">Turno agendado correctamente.</p>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};
