import React, { useEffect, useState } from "react";
import "../styles/turnos-sj.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";
import "animate.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dataResumen } from "../actions/turnos";
import { Header } from "./Header/Header";
import { getDiasMes, getHorarios, redirectUser } from "../services/turnos";
import { validarPatente } from "../services/form";
import { ModalLoading } from "./Modals/ModalLoading";
import { addWeeks, isAfter, isBefore } from "date-fns";
import LogoComponent from "./Logo/LogoComponent";
import LoadingComponent from "./Loading/LoadingComponent";
import SelectComponent from "./Select/SelectComponent";
import LocationComponent from "./Location/LocationComponent";
import CalendarComponent from "./Calendar/CalendarComponent";
import HoursComponent from "./Hours/HoursComponent";
import PlateComponent from "./Plate/PlateComponent";
import ButtonSendComponent from "./Buttons/ButtonSendComponent";

export const TurnosSJ = () => {
  const { data, loading, status } = useSelector((state) => state.turnosReducer);
  const { email } = useSelector((state) => state.authReducer);

  const [activeCalendar, setactiveCalendar] = useState(false);
  const [activeHorario, setactiveHorario] = useState(false);
  const [activeLocation, setActiveLocation] = useState(false);
  const [companySelected, setCompanySelected] = useState("");
  const [diaSeleccionado, setdiaSeleccionado] = useState("");
  const [horarioSeleccionado, sethorarioSeleccionado] = useState("");
  const [locationSelect, setLocationSelect] = useState("");
  const [horarios, sethorarios] = useState();
  const [daysDisabledOneMonth, setDaysDisabledOneMonth] = useState();
  const [daysDisabledTwoMonth, setDaysDisabledTwoMonth] = useState();
  const [validandoAppointment, setvalidandoAppointment] = useState();
  const [valuePatente, setvaluePatente] = useState("");
  const [patenteValida, setPatenteValida] = useState(false);
  const [allDaysDisabled, setallDaysDisabled] = useState();
  const [conc, setconc] = useState();
  const [modal, setmodal] = useState(false);
  const [isTake, setisTake] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { deal_id } = useParams();

  const handleSelectCompany = (location, company) => {
    setLocationSelect(location);
    setCompanySelected(company);
    setActiveLocation(false);
    sethorarioSeleccionado("");
    setdiaSeleccionado("");
  };

  const showCalendar = () => {
    setactiveCalendar(!activeCalendar);
    setActiveLocation(false);
    setactiveHorario(false);
  };
  const showHorario = () => {
    setactiveHorario(!activeHorario);
    setactiveCalendar(false);
    setActiveLocation(false);
  };

  const showLocation = () => {
    setActiveLocation(!activeLocation);
    setactiveHorario(false);
    setactiveCalendar(false);
  };

  useEffect(() => {
    dataResumen(dispatch, deal_id);
    if (pathname.includes("take")) {
      setisTake(true);
      if (data !== undefined) {
      } else {
        return redirectUser(
          "pretake",
          deal_id,
          setvalidandoAppointment,
          navigate
        );
      }
    } else {
      setisTake(false);
      if (data !== undefined) {
      } else {
        return redirectUser(
          "check",
          deal_id,
          setvalidandoAppointment,
          navigate
        );
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const inputPatente = e.target.value.toUpperCase();
    setvaluePatente(inputPatente);
    setPatenteValida(validarPatente(inputPatente));
  };

  const seleccionarDia = (data) => {
    const diaConvertido = data.getUTCDate();
    const mesConvertido = data.getMonth();

    const esEsteMes = mesConvertido === new Date().getMonth();

    let found;
    if (esEsteMes) {
      found = daysDisabledOneMonth.find((dias) => dias === diaConvertido);
    } else {
      found = daysDisabledTwoMonth.find((dias) => dias === diaConvertido);
    }

    if (!found) {
      setdiaSeleccionado(data);
      setactiveCalendar(!activeCalendar);
    }
  };

  useEffect(() => {
    getHorarios(diaSeleccionado, sethorarios, companySelected);
  }, [diaSeleccionado, companySelected]);

  const seleccionarHorarios = (data) => {
    sethorarioSeleccionado(data);
    setactiveHorario(!activeHorario);
  };

  useEffect(() => {
    getDiasMes(
      setDaysDisabledOneMonth,
      setDaysDisabledTwoMonth,
      setallDaysDisabled,
      companySelected
    );
  }, [companySelected]);

  const fechaActual = new Date();

  // Cambiar la duración de una semana a 4 días
  const cuatroDiasEnMilisegundos = 4 * 24 * 60 * 60 * 1000;

  // Mantener la duración de un mes
  const unaMesEnMilisegundos = 30 * 24 * 60 * 60 * 1000;

  // Fecha actual + 4 días
  const fechaSumada = new Date(
    fechaActual.getTime() + cuatroDiasEnMilisegundos
  );

  useEffect(() => {
    if (allDaysDisabled !== undefined) {
      const diasDisabled = allDaysDisabled.map(
        (info) => new Date(2024, info.numberMonth, info.dia)
      );
      const befores = [{ before: new Date() }];
      const conca = diasDisabled.concat(befores);
      if (!email && !isTake) {
        const afters = [
          {
            from: fechaSumada,
            to: new Date(fechaSumada.getTime() + unaMesEnMilisegundos),
          },
        ];
        const conca2 = conca.concat(afters);
        setconc(conca2);
      } else {
        setconc(conca);
      }
    }
  }, [allDaysDisabled]);

  return (
    <div className="container-app">
      <LogoComponent />
      {validandoAppointment === undefined &&
      data !== "cancelar" &&
      data !== "modificar" ? (
        <LoadingComponent />
      ) : (
        <div className="card-appointment">
          <Header />
          <p
            className="title-card"
            style={{ marginTop: email ? "5px" : "40px" }}
          >
            {data === "cancelar"
              ? `Turno cancelado, ¿desea agendar una nueva ${
                  isTake ? "toma" : "inspección"
                }?`
              : data === "modificar"
              ? `Modificar turno - Agendar ${isTake ? "toma" : "inspección"}`
              : isTake
              ? "Agendar toma"
              : "Agendar inspección"}
          </p>
          <div style={{ display: "flex" }}>
            <div className="subcontainer-card">
              {!isTake ? (
                <>
                  <SelectComponent
                    label={"Punto de inspección"}
                    value={locationSelect}
                    show={showLocation}
                    active={activeLocation}
                    placeholder={"Seleccione un punto de inspección"}
                  />
                  <LocationComponent
                    active={activeLocation}
                    handleSelectCompany={handleSelectCompany}
                  />
                </>
              ) : null}
              <SelectComponent
                label={"Fecha"}
                value={diaSeleccionado}
                show={locationSelect !== "" || isTake ? showCalendar : null}
                active={activeCalendar}
                placeholder={"Seleccione una fecha"}
                isCalendar={true}
              />
              <CalendarComponent
                activeCalendar={activeCalendar}
                conc={conc}
                fechaActual={fechaActual}
                sethorarioSeleccionado={sethorarioSeleccionado}
                addWeeks={addWeeks}
                isBefore={isBefore}
                email={email}
                isTake={isTake}
                isAfter={isAfter}
                seleccionarDia={seleccionarDia}
                companySelected={companySelected}
              />
              <SelectComponent
                label={"Horario"}
                value={horarioSeleccionado}
                show={diaSeleccionado !== "" ? showHorario : null}
                active={activeHorario}
                placeholder={"Seleccione un horario"}
              />
              <HoursComponent
                activeHorario={activeHorario}
                horarios={horarios}
                seleccionarHorarios={seleccionarHorarios}
              />
              {!isTake ? (
                <PlateComponent
                  valuePatente={valuePatente}
                  handleInputChange={handleInputChange}
                  diaSeleccionado={diaSeleccionado}
                  horarioSeleccionado={horarioSeleccionado}
                  locationSelect={locationSelect}
                />
              ) : null}
              <ButtonSendComponent
                locationSelect={locationSelect}
                patenteValida={patenteValida}
                diaSeleccionado={diaSeleccionado}
                horarioSeleccionado={horarioSeleccionado}
                isTake={isTake}
                valuePatente={valuePatente}
                deal_id={deal_id}
                navigate={navigate}
                email={email}
                dispatch={dispatch}
                setmodal={setmodal}
              />
            </div>
          </div>
        </div>
      )}
      <ModalLoading
        modal={modal}
        setmodal={setmodal}
        loading={loading}
        status={status}
      />
    </div>
  );
};
