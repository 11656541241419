import React from "react";
import "./style.css";

const HoursComponent = ({ activeHorario, horarios, seleccionarHorarios }) => {
  return (
    <>
      <div className={activeHorario ? "container-hours" : "displayNone"}>
        {activeHorario && horarios !== undefined
          ? horarios.map((info, i) => (
              <div key={i}>
                <div
                  className="container-buttons-option-hours"
                  key={info.horario}
                  style={{
                    border: `1px solid ${
                      info.available === true ? "#393839" : "#e2e2e2"
                    } `,
                    color: `${
                      info.available === true ? "#393839" : "#e2e2e2"
                    } `,
                    cursor: `${info.available === true ? "pointer" : "auto"} `,
                  }}
                >
                  <div
                    onClick={() =>
                      info.available === true
                        ? seleccionarHorarios(info.horario)
                        : null
                    }
                  >
                    {info.horario}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export default HoursComponent;
