import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { login } from "../actions/auth";
import { Login } from "../components/Login";
import { ResumenSJ } from "../components/Resumen/ResumenSJ";
import { ResumenTake } from "../components/Resumen/ResumenTake";
import { TurnosSJ } from "../components/TurnosSJ";
import { firebase } from "../firebase/firebase-config";

export const AppRouter = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user?.uid) {
        dispatch(
          login(user.uid, user.displayName ? user.displayName : "", user.email)
        );
        // setisLogged(true);
      }
    });
  }, [dispatch]);

  return (
    <div>
      <Router>
        <Routes>
          <Route
            exact
            path="/resumen-san-jorge/:deal_id"
            element={<ResumenSJ />}
          />
          <Route
            exact
            path="/turnos-san-jorge/:deal_id"
            element={<TurnosSJ />}
          />
          <Route
            exact
            path="/turnos-san-jorge/take/:deal_id"
            element={<TurnosSJ />}
          />
          <Route
            exact
            path="/resumen-san-jorge/take/:deal_id"
            element={<ResumenTake />}
          />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
};
