import React, { useEffect, useState } from "react";
import IconoCalendar from "../../images/san-jorge/icono-calendar.png";
import IconoHorario from "../../images/san-jorge/icono-horario.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelarTurnoDb,
  modificarTurnoDb,
  dataResumen,
} from "../../actions/turnos";
import "animate.css";
import LogoComponent from "../Logo/LogoComponent";
import ButtonComponent from "../Buttons/ButtonComponent";
import FooterComponent from "../Footer/FooterComponent";
import ModalCancelEditComponent from "../Modals/ModalCancelEditComponent";
import "./style.css";

export const ResumenSJ = () => {
  const [showModalEdit, setshowModalEdit] = useState(false);
  const [showModalCancel, setshowModalCancel] = useState(false);
  const [loadingResponse, setLoadingResponse] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deal_id } = useParams();

  useEffect(() => {
    dataResumen(dispatch, deal_id, navigate);
  }, []);

  const {
    horarioSeleccionado,
    diaSeleccionado,
    año,
    marca,
    modelo,
    version,
    patente,
  } = useSelector((state) => state.turnosReducer);

  const { horarioSeleccionadoNew, diaSeleccionadoNew, patenteNew } =
    useSelector((state) => state.dataReducer);

  const { email } = useSelector((state) => state.authReducer);

  const modalEditar = () => {
    setshowModalEdit(!showModalEdit);
  };

  const modalCancelar = () => {
    setshowModalCancel(!showModalCancel);
  };

  const editarTurno = () => {
    setLoadingResponse(true);
    modificarTurnoDb(deal_id, email, navigate, dispatch, setLoadingResponse);
  };

  const cancelarTurnos = () => {
    setLoadingResponse(true);
    cancelarTurnoDb(deal_id, email, navigate, dispatch, setLoadingResponse);
  };

  return (
    <>
      <ModalCancelEditComponent
        show={showModalEdit}
        setShow={setshowModalEdit}
        text={"editar"}
        handleEvent={editarTurno}
        loadingResponse={loadingResponse}
      />
      <ModalCancelEditComponent
        show={showModalCancel}
        setShow={setshowModalCancel}
        text={"cancelar"}
        handleEvent={cancelarTurnos}
        loadingResponse={loadingResponse}
      />
      <div className="container-app">
        <LogoComponent />
        <div className="card-resumen animate__fadeIn">
          <p className="title-card-resumen">Mi turno</p>
          <div className="d-flex align-items-center justify-content-between">
            <p className="text-type-resumen">Inspeccion tecnica</p>
            <div className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center justify-content-center">
                <img
                  src={IconoCalendar}
                  alt="icon-calendar"
                  className="icon-resumen"
                />
                <p className="text-date-hours">
                  {diaSeleccionado !== undefined
                    ? diaSeleccionado
                    : diaSeleccionadoNew}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center ms-4 me-5">
                <img
                  src={IconoHorario}
                  alt="icon-hours"
                  className="icon-resumen"
                />
                <p className="text-date-hours">
                  {horarioSeleccionado !== undefined
                    ? horarioSeleccionado
                    : horarioSeleccionadoNew}
                </p>
              </div>
            </div>
          </div>
          <p className="title-car">Vehiculo</p>
          <div className="d-flex align-items-center justify-content-start">
            <p
              className="text-info-car"
              style={{ marginLeft: "40px", marginRight: "20px" }}
            >
              {marca}
            </p>
            <p className="text-info-car">{modelo}</p>
            <p
              className="text-info-car"
              style={{ marginLeft: "5px", marginRight: "5px" }}
            >
              -
            </p>
            <p className="text-info-car">{version}</p>
          </div>
          <p className="text-type-resumen" style={{ marginTop: "10px" }}>
            {año}
          </p>
          <p className="text-type-resumen" style={{ marginTop: "10px" }}>
            Patente: {patenteNew ? patenteNew : patente}
          </p>
          <div className="contain-btns-resumen">
            <ButtonComponent
              type="contain"
              text={"Editar turno"}
              handleEvent={modalEditar}
            />
            <ButtonComponent
              type="outline"
              text={"Cancelar turno"}
              handleEvent={modalCancelar}
            />
          </div>
        </div>
        <FooterComponent />
      </div>
    </>
  );
};
